import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const HalfStickyTeaserBlock = ({ block }) => {
  const placeholderImage = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
  return (
    <BlockWrapper
      block={block}
      blockWidth="screen"
      blockPadding=""
      showHeadline="false"
      className="lg:px-8 bg-primary"
    >
      <div className="w-full max-w-screen-lg mx-auto">
        <div className="flex flex-row flex-wrap">
          <div className="w-full text-white md:w-1/2">
            <div className="sticky top-0 px-8 py-16 md:py-32 lg:pl-0 lg:pr-8">
              <HeadlineEnhanced
                kicker={block.kicker}
                headline={block.headline}
                subheadline={block.subheadline}
              />
            </div>
          </div>
          <div className="w-full bg-gray-200 md:w-1/2">
            {block.teaser_items?.map((item, index) => (
              <Link key={index} className="block" link={item.link}>
                <Image
                  className="block w-full h-auto"
                  image={
                    item.image === undefined || item.image?.filename === ""
                      ? placeholderImage
                      : item.image
                  }
                />
                <div className="p-8 pb-16 ">
                  <HeadlineEnhanced
                    kicker={item.kicker}
                    headline={item.headline}
                    subheadline={item.subheadline}
                    headlineLevel="h4"
                  />
                  <Richtext className="mt-4" text={item.text} />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default HalfStickyTeaserBlock
